import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters, dispatch } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    search: route.query.search,
    page: 1,
    partyCnt: 0,
    partyList: [],
    wizzpediaCnt: 0,
    wizzpediaList: [],
    faqCnt: 0,
    faqList: [],
    marketCnt: 0,
    marketList: [],
    noticeCnt: 0,
    noticeList: [],
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const { fnWrite, fnMarketWrite, fnUpdateAuthType } = CommonFunction(state)
  const fnPartyList = async () => {
    const params = {
      startNum: 1,
      endNum: 4,
      searchText: state.search,
      categoryId: '',
      sort: 'MODDT_ASC',
      endYn: 'Y'
    }
    const res = await proxy.$PartySvc.postPartyList(params)
    if (res.resultCode === '0000') {
      state.partyCnt = res.totalCnt
      state.partyList = res.list
    } else if (res.resultCode === '0001') {
      state.partyCnt = 0
      state.partyList = []
    } else {
      alert(res.resultMsg)
    }
  }
  const fnWizzpediaList = async () => {
    const params = {
      page: 1,
      search: state.search,
      category: ''
    }
    const res = await proxy.$WizzpediaSvc.postWizzpediaSearchWizzpedia(params)
    if (res.list.length > 4) {
      state.wizzpediaList = res.list.slice(0, 4)
    } else {
      state.wizzpediaList = res.list
    }
    state.wizzpediaCnt = res.count || 0
  }
  const fnMarketList = async () => {
    const params = {
      startNum: 1,
      endNum: 4,
      searchText: state.search,
      categoryId: '',
      sort: 'REGDT_ASC',
      endYn: 'Y'
    }
    const res = await proxy.$MarketSvc.postMarketList(params)
    if (res.resultCode === '0000') {
      state.marketCnt = res.totalCnt
      state.marketList = res.list
    } else if (res.resultCode === '0001') {
      state.marketCnt = 0
      state.marketList = []
    } else {
      alert(res.resultMsg)
    }
  }
  const fnNoticeList = async () => {
    const params = {
      startNum: 1,
      endNum: 4,
      searchText: state.search
    }
    const res = await proxy.$CustomerSvc.postNotice(params)
    if (res.resultCode === '0000') {
      state.noticeCnt = res.totalCnt
      state.noticeList = res.list
    } else if (res.resultCode === '0001') {
      state.noticeCnt = 0
      state.noticeList = []
    } else {
      alert(res.resultMsg)
    }
  }
  const fnNoticeView = item => {
    router.push({
      path: '/cs/notice/view',
      query: { noticeId: item.noticeId }
    })
  }

  const setNoticeTypeClassName = (noticeType = '공지') => {
    return noticeType === '공지' ? 'sec_color' : 'color'
  }

  const fnFaqList = async () => {
    const params = {
      startNum: 1,
      endNum: 4,
      searchText: state.search
    }
    const res = await proxy.$CustomerSvc.postFaq(params)
    if (res.resultCode === '0000') {
      state.faqCnt = res.totalCnt
      state.faqList = res.list.map(item => ({
        ...item,
        isShow: false
      }))
    } else if (res.resultCode === '0001') {
      state.faqCnt = 0
      state.faqList = []
    } else {
      alert(res.resultMsg)
    }
  }
  const fnMore = async (type = 1) => {
    const query = {
      search: state.search
    }
    const categoryMap = proxy.$_.keyBy(
      getters['nav/getCategoryList'],
      'categoryID'
    )
    if (type === 1) {
      await router.push({ path: '/party/search', query: query })
    } else if (type === 2) {
      // 위즈피디아
      await dispatch('nav/updateOnDepth1Item', {
        onDepth1Item: categoryMap[3000]
      })
      await router.push({ path: '/community/wizzpedia/search', query: query })
    } else if (type === 3) {
      await router.push({ path: '/market/search', query: query })
    } else if (type === 4) {
      await router.push({ name: 'cs-faq-index', query })
    } else if (type === 5) {
      await router.push({ name: 'cs-notice-index', query })
    }
  }

  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  const init = async () => {
    await fnPartyList()
    await fnMarketList()
    await fnNoticeList()
    await fnFaqList()
    await fnWizzpediaList()
  }
  init()
  return {
    ...toRefs(state),
    fnMore,
    fnWrite,
    fnMarketWrite,
    fnCallback,
    setNoticeTypeClassName,
    fnNoticeView
  }
}
