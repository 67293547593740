<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl" style="height: auto;">
            <span>"{{ search }}" 검색 결과</span>
          </div>
          <div class="party_list_wrap">
            <div class="list_top result">
              <!--'검색결과 페이지: result'-->
              <div class="left">
                <div class="list_top_ttl">
                  구독 {{ $Util.formatNumber2(partyCnt, { unit: '' }) }} 건
                </div>
              </div>
              <div class="right" v-if="partyCnt > 0">
                <button class="li_btn more" @click="fnMore(1)">더보기</button>
              </div>
            </div>
            <party-list
              :row-type="1"
              :items="partyList"
              v-if="!$Util.isEmpty(partyList)"
            />
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>파티 검색 결과가 없습니다.</div>
              </div>
              <button
                class="btn"
                @click="fnWrite"
                v-if="!$Util.isEmpty(userData)"
              >
                파티 생성하기
              </button>
            </div>
          </div>
          <div class="party_list_wrap">
            <div class="list_top result">
              <!--'검색결과 페이지: result'-->
              <div class="left">
                <div class="list_top_ttl">
                  마켓 {{ $Util.formatNumber2(marketCnt, { unit: '' }) }} 건
                </div>
              </div>
              <div class="right" v-if="marketCnt > 0">
                <button class="li_btn more" @click="fnMore(3)">더보기</button>
              </div>
            </div>
            <market-list
              :row-type="1"
              :items="marketList"
              v-if="!$Util.isEmpty(marketList)"
            />
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>마켓 검색 결과가 없습니다.</div>
              </div>
              <button
                class="btn"
                @click="fnMarketWrite"
                v-if="!$Util.isEmpty(userData)"
              >
                마켓 등록하기
              </button>
            </div>
          </div>
          <div class="party_list_wrap">
            <div class="list_top result">
              <!--'검색결과 페이지: result'-->
              <div class="left">
                <div class="list_top_ttl">
                  공지사항
                  {{ $Util.formatNumber2(noticeCnt, { unit: '' }) }} 건
                </div>
              </div>
              <div class="right" v-if="noticeCnt > 0">
                <button class="li_btn more" @click="fnMore(5)">더보기</button>
              </div>
            </div>
            <div class="empty_wrap" v-if="noticeCnt < 1">
              <div class="empty">
                <div>
                  공지사항 검색 결과가 없습니다.
                </div>
              </div>
            </div>
            <div
              class="notice_list"
              style="position: relative; padding-bottom: 2rem;"
              v-else
            >
              <ul class="notice_list_ul search" style="padding: 0 2rem;">
                <li
                  class="notice_list_li search"
                  v-for="(item, index) in noticeList"
                  :key="index"
                  @click="fnNoticeView(item)"
                >
                  <div class="notice_list_ttl">
                    <div class="left">
                      <span
                        class="cate"
                        :class="setNoticeTypeClassName(item.noticeType)"
                        >{{ item.noticeType }}</span
                      >
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="right"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="party_list_wrap">
            <div class="list_top result">
              <!--'검색결과 페이지: result'-->
              <div class="left">
                <div class="list_top_ttl">
                  FAQ
                  {{ $Util.formatNumber2(faqCnt, { unit: '' }) }} 건
                </div>
              </div>
              <div class="right" v-if="faqCnt > 0">
                <button class="li_btn more" @click="fnMore(4)">더보기</button>
              </div>
            </div>
            <div class="empty_wrap" v-if="faqCnt < 1">
              <div class="empty">
                <div>
                  FAQ 검색 결과가 없습니다.
                </div>
              </div>
            </div>
            <div
              class="notice_list"
              style="position: relative; padding-bottom: 2rem;"
              v-else
            >
              <ul class="faq_list_ul search" style="padding: 0 2rem;">
                <li
                  class="faq_list_li search"
                  v-for="(item, index) in faqList"
                  :key="index"
                >
                  <button
                    class="faq_list_ttl list"
                    @click="() => (item.isShow = !item.isShow)"
                  >
                    <span class="left content">
                      <span>Q. {{ item.contents }}</span>
                    </span>
                    <span class="right">
                      <span class="cate sec_color">{{
                        item.questionType
                      }}</span>
                    </span>
                  </button>
                  <div class="faq_list_con" :class="{ on: item.isShow }">
                    <div class="editor">
                      <div>
                        {{ item.comments }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="party_list_wrap">
            <div class="list_top result">
              <!--'검색결과 페이지: result'-->
              <div class="left">
                <div class="list_top_ttl">
                  위즈피디아
                  {{ $Util.formatNumber2(wizzpediaCnt, { unit: '' }) }} 건
                </div>
              </div>
              <div class="right" v-if="wizzpediaCnt > 0">
                <button class="li_btn more" @click="fnMore(2)">더보기</button>
              </div>
            </div>
            <div class="wzp_list" v-if="!$Util.isEmpty(wizzpediaList)">
              <wizzpedia-list :list="wizzpediaList" />
            </div>

            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>위즈피디아 검색 결과가 없습니다.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice12'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
import PartyList from '@/components/views/party/list/index.vue'
import WizzpediaList from '@/components/views/community/wizzpedia/list/index.vue'
import MarketList from '@/components/views/market/list/index.vue'
export default {
  name: 'main-search-index',
  components: {
    PartyList,
    WizzpediaList,
    MarketList
  },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
