<template>
  <ul class="wzp_list_ul">
    <li
      class="wzp_list_li"
      v-for="(item, index) in list"
      :key="index"
      style="cursor: pointer"
      @click="fnView(item)"
    >
      <div class="box txt">
        <div class="party_ttl">
          <div class="party_tag">
            <div class="left">
              <div
                class="pt_tag logo"
                style="--pt-bg-color--pt-txt-color: #fff;"
                :style="{
                  '--pt-bg-color': $ConstCode.getCategoryByText(item.category)
                    .color
                }"
              >
                {{ $ConstCode.getCategoryByText(item.category).text }}
              </div>
            </div>
          </div>
          <span>{{ item.titleKr }}</span>
        </div>
        <div class="wzp_tag">
          <span>{{ item.genre }}</span>
          <span>{{ item.mfg }}</span>
        </div>
      </div>
      <div class="box img">
        <div class="thumb">
          <div class="inner">
            <img :src="item.thumbnail" :alt="item.titleKr" />
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-community-wizzpedia-list-index',
  props: {
    list: {
      type: [Array],
      default: () => []
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
