import {
  reactive,
  toRefs,
  onActivated,
  getCurrentInstance,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'
// 배너리스트관련은 전부 제외해도 됩니다.
export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    listScrollTop: 0,
    pointer: computed(() => (window.PointerEvent ? true : false)),
    activeTab: 2,
    touch: {}
  })
  const { switchTab, start, move, end, setTouchPos } = CommonFunction(state)
  const fnView = async item => {
    const pageCon = document.querySelector('#page_con')
    if (pageCon) {
      state.listScrollTop = pageCon.scrollTop
    }
    await router.push({
      path: '/community/wizzpedia/view',
      query: { id: item.id, category: item.category }
    })
  }
  onActivated(() => {
    if (!proxy.$Util.isEmpty(state.listScrollTop) && state.listScrollTop > 0) {
      document.querySelector('#page_con').scrollTop = state.listScrollTop
      state.listScrollTop = 0
    }
  })
  return {
    ...props,
    ...toRefs(state),
    fnView,
    switchTab,
    start,
    move,
    end,
    setTouchPos
  }
}
